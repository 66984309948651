import { Permission, PermissionView, userFactory } from "../../services";
import { Options, setup, Vue } from "vue-class-component";

@Options({
  props: {
    selectedPermission: Array,
    disabled: Boolean,
  },
  components: {},
  emits: ["onChanged"]
})

export default class PermissionsComponent extends Vue {

  userBloc = setup(() => userFactory())
  permissions: PermissionView[] = [];
  selectedPermission: Permission[] = [];
  methods: string[] = []
  iconMethod = [
    {
      method: 'View',
      iconClass: 'far fa-eye'
    },
    {
      method: 'Create',
      iconClass: 'fas fa-plus'
    },
    {
      method: 'Edit',
      iconClass: 'fas fa-pencil-alt'
    },
    {
      method: 'Delete',
      iconClass: 'fas fa-trash-alt'
    }
  ]

  async mounted() {
    this.permissions = await this.userBloc.getPermissions();
    console.log(this.permissions);
    this.methods = this.permissions.map((p) => p.methods).sort((a, b) => b.length - a.length)[0]
  }

  getIconClass(method: string) {
    const selected = this.iconMethod.find((i) => i.method == method);
    if (selected) return selected.iconClass;
    return 'far fa-question-circle'
  }

  getPermissionList(data: any[]) {
    const permissionName = [...new Set(data.map((p) => p.name))];
    return permissionName.map((a) => ({
      name: a,
      method: data.filter((p) => p.name == a).map((p) => p.method)
    }));
  }

  isChecked(method: string, permissionName: string) {
    try {
      return this.selectedPermission.map((p) => p.value).includes(method + ' ' + permissionName);
    } catch (error) {
      return false;
    }
  }

  changed(e) {
    const name = e.target.value;
    const value = e.target.id;
    if (e.target.checked) {
      this.selectedPermission.push({
        name: name,
        value: value,
      });
    } else {
      this.selectedPermission.forEach((row: Permission, index: number) => {
        if (row.value == value) this.selectedPermission.splice(index, 1);;
      });
    }
    this.$emit("onChanged", this.selectedPermission);
  }
}