import { User, userApi, UserDetail } from "./index"
import { ref } from "vue"
import environment from "@/config"
import { helper } from "@cems-eco/core/src/utils/helper"
import { IPermissionView, Permission, PermissionView } from "./model"
import { Client, clientApi } from "../../client/services"


export function userFactory() {
    const module = 'user';
    const api = userApi;
    const context = ref([]);
    const base_url = environment.API_URL + '/' + module;

    async function getUsers(): Promise<[User]> {
        let response = await api.all()
        return response.data.data;
    }

    async function getUser(id: any): Promise<User> {
        let response = await api.first(id)
        return response.data;
    }

    async function createUser(user: User): Promise<User> {
        let response = await api.create(user)
        return response.data;
    }

    async function updateUser(user: User): Promise<User> {
        let response = await api.edit(user.id, user)
        return response.data;
    }

    // FIXME: not working
    async function destroyUser(id: any): Promise<void> {
        let response = await api.destroy(id)
        return response.data;
    }

    async function searchParams(user: User): Promise<string> {
        const uri = helper.toSerializeDatatable(user)
        return base_url + encodeURI(uri);
    }

    async function getPermissions(): Promise<PermissionView[]> {
        let response = await api.getPermissions();
        return getPermissionsView(response.data);
    }

    async function getUserDetail(id: string): Promise<UserDetail> {
        let response = await api.getUserDetail(id)
        return response.data;
    }

    async function createUserDetail(userDetail: UserDetail): Promise<UserDetail> {
        let response = await api.createUserDetail(userDetail)
        return response.data;
    }

    async function updateUserDetail(userDetail: UserDetail): Promise<UserDetail> {
        let response = await api.updateUserDetail(userDetail.user.id, userDetail)
        return response.data;
    }

    async function deleteUserDetail(id: any): Promise<void> {
        let response = await api.deleteUserDetail(id)
        return response.data;
    }

    async function getClients(): Promise<Client[]> {
        let response = await clientApi.all()
        return response.data.data;
    }
    
    function getPermissionsView(permissions: Permission[]): PermissionView[] {

        const module = [...new Set<string>(permissions.map((p) => p.name))];

        const permissionView = module.map((m) => {
            const permissionList: string[] = permissions.filter((p) => p.name == m).map((p) => p.value);
            // const subs = [...new Set(permissionList.map((p: string) => p.split(' ')[1]))];
            const methods = [...new Set(permissionList.map((p: string) => p.split(' ')[0]))].sort((a, b) => a.localeCompare(b));

            const permission = permissionList.map((sub) => ({
                name: sub.split(' ')[1],
                method: methods.indexOf(sub.split(' ')[0]),
                selected: false,
            } as IPermissionView));

            return new PermissionView({
                name: m,
                permission: permission,
                methods: methods,
            })
        });        

        return permissionView;
    }

    return {
        api,
        base_url,
        getUsers,
        getUser,
        createUser,
        updateUser,
        destroyUser,
        searchParams,
        getPermissions,
        getUserDetail,
        createUserDetail,
        updateUserDetail,
        deleteUserDetail,
        getClients,
    }

}

