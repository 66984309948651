import { Vue, setup } from "vue-class-component";
import { widget } from "@cems-eco/core/src/utils/widget";
import { Sensor, sensorFactory } from "../services";
import $ from "jquery";
import environment from "@/config";
import storage from "@/storage";

export default class SensorPage extends Vue {

    sensorBloc = setup(() => sensorFactory())
    sensors: object[] = []
    options = {}
    events: object[] = []
    sensor = new Sensor
    isSearching = true;
    dt: any;
    clientS2: any;
    siteS2: any;
    equipmentS2: any;
    adv: any;
    clientOptions: any = {
        placeholder: '',
        allowClear: true,
        data: [],
    };
    siteOptions: any = {
        placeholder: '',
        allowClear: true,
        disabled: true,
        data: [],
    };
    equipmentOptions: any = {
        placeholder: '',
        allowClear: true,
        disabled: true,
        data: [],
    };
    created(): void {
        this.clientOptions = this.initOption(environment.API_URL + '/client/select2');
        this.siteOptions = { disabled: true, ...this.initOption(environment.API_URL + '/site/select2') };
        this.equipmentOptions = { disabled: true, ...this.initOption(environment.API_URL + '/equipment/select2') };
    }

    mounted() {
        this.builDataTable();
        this.getAdvanceSearch();
    }

    initOption(endpoint: string) {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        return {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url: endpoint,
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    async builDataTable() {
        const loader: any = await widget.loading();
        const scope: any = this;
        loader.showLoading();


        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }
        this.options = {
            serverSide: true,
            searching: !this.isSearching,
            ajax: {
                url: this.sensorBloc.base_url,
                method: 'GET',
                dataType: 'json',
                dataFilter: (data) => {
                    const parsedData = JSON.parse(data);
                    return JSON.stringify(parsedData.data);
                },
                headers,
            },
            columnDefs: [
                {
                    "targets": -1,
                    "width": "1%"
                },
            ],
            columns: [
                { data: 'client_name' },
                { data: 'site_name' },
                { data: 'equipment_name' },
                { data: 'name' },
                {
                    title: "Action",
                    data: "id",
                    render: function (id: any, type: any, full: any, meta: any) {
                        return (
                            '<div class="form-group" style="margin: auto;">' +
                            '<div class="btn-group" role="group">' +
                            `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye" data-placement="top" title="View" ></i></button>` +
                            `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" data-placement="top" title="Edit"></i></button>` +
                            `<button type="button" class="btn btn-danger btn-sm delete"id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
                            `<button type="button" class="btn btn-warning btn-sm duplicate" id="duplicateAction"><i class="far fa-regular fa-copy" ></i></button>` +
                            "</div>" +
                            "</div>"
                        );
                    }
                }
            ],
            createdRow: function (row: any, data: any) {
                $(row).on("click", "#viewAction", scope.viewData.bind(this, data, scope));
                $(row).on("click", "#editAction", scope.editData.bind(this, data, scope));
                $(row).on("click", "#deleteAction", scope.deleteData.bind(this, data, scope));
                $(row).on("click", "#duplicateAction", scope.duplicateData.bind(this, data, scope));
            }
        };
        loader.close();
    }

    dtInstance(dt) {
        this.dt = dt;
    }

    clientSelectChanged(data: any[]) {
        this.sensor.client_id = data[0].id;
        const isDisabled = this.sensor.client_id == undefined || this.sensor.client_id == '';
        this.siteOptions.disabled = isDisabled;
        if (!this.equipmentOptions.disabled) {
            this.equipmentOptions.disabled = isDisabled;
            this.equipmentOptions.data = [];
        }
        if (isDisabled) this.siteOptions.data = []
        this.siteOptions.ajax.url = environment.API_URL + '/site/select2/client/' + this.sensor.client_id;
        this.siteS2.trigger('change.select2');
    }

    siteSelectChanged(data: any[]) {
        this.sensor.site_id = data[0].id;
        const isDisabled = this.sensor.site_id == undefined || this.sensor.site_id == '';
        this.equipmentOptions.disabled = isDisabled;
        if (isDisabled) this.equipmentOptions.data = []
        this.equipmentOptions.ajax.url = environment.API_URL + '/equipment/select2/site/' + this.sensor.site_id;
    }

    equipmentSelectChanged(data: any[]) {
        this.sensor.equipment_id = data[0].id;
    }

    openSearch() {
        this.isSearching = !this.isSearching;
        this.builDataTable();
    }

    async searching() {
        this.isSearching = true;
        let data: any = {
            client_id: this.sensor.client_id,
            site_id: this.sensor.site_id,
            equipment_id: this.sensor.equipment_id,
            isSearching: this.isSearching
        }
        await storage.set<any>(storage.SENSOR_ADV_SEARCH, data);
        const searchUrl = await this.sensorBloc.searchParams(this.sensor);

        this.dt.ajax.url(searchUrl);
        this.dt.ajax.reload();
    }

    clearStorage() {
        this.clientS2.val(null).trigger('change');
        this.siteS2.val(null).trigger('change');
        this.equipmentS2.val(null).trigger('change');
        storage.clear("sensor_adv_search");
    }

    clientInstance(s2) {
        this.clientS2 = s2;
        try {
            this.clientS2.val([{ id: this.adv.client_id }]).trigger('change');
        } catch (e) { }
    }

    siteInstance(s2) {
        this.siteS2 = s2;
    }

    equipmentInstance(s2) {
        this.equipmentS2 = s2;
    }

    async getAdvanceSearch() {
        let advSearchData: any = await storage.get<any>(storage.SENSOR_ADV_SEARCH);
        try {
            this.isSearching = advSearchData.isSearching;
        } catch (error) { }
        this.adv = advSearchData;
    }

    async viewData(data: any) {
        this.$router.push(`/sensor/${data.id}`);
    }

    async duplicateData(data: any) {
        this.$router.push(`/sensor/create?duplicate=${data.id}`);
    }

    async editData(data: any) {
        this.$router.push(`/sensor/${data.id}/edit`);
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.')
                    return;
                }
                let message = await this.sensorBloc.deleteRelation(data.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.builDataTable();
            } catch (error) {
                widget.alertError(error);
            }
        })
    }

}

