import { api } from '@cems-eco/core/src/services/api';
import { Sensor } from './index';

export class SensorApi {
    private BASE_URL: string;

    constructor() {
        this.BASE_URL = "/sensor";
    }

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async create(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async edit(id: any, data: any): Promise<any> {
        return await api.put<any>(`${this.BASE_URL}/${id}`, data, true);
    }

    async destroy(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_URL}/${id}`, true);
    }

    async byEquipment(equipment_id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/select2/equipment/${equipment_id}`, true);
    }

    async storeRelation(data: any): Promise<any> {
        return await api.post<any>(`${this.BASE_URL}/relation/store`, data, true);
    }

    async updateRelation(id: any, data: any): Promise<any> {
        return await api.post<any>(`${this.BASE_URL}/relation/${id}`, data, true);
    }

    async deleteRelation(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_URL}/relation/${id}`, true);
    }

}

const sensorApi = new SensorApi();
export { sensorApi };
