import { Vue, setup } from "vue-class-component";
import { widget } from "@cems-eco/core/src/utils/widget";
import { Sensor, sensorFactory } from "../../../sensor/services";
import $ from "jquery";
import storage from "@/storage";

export default class SensorViewComponent extends Vue {
  sensorBloc = setup(() => sensorFactory())
  sensors: object[] = []
  options = {}
  events: object[] = []
  sensor = new Sensor
  isSearching = true;
  dt: any;
  adv: any;
  equipment: any;

  mounted() {
    this.getByEquipment();
    this.builDataTable();
  }

  async builDataTable() {
    const loader: any = await widget.loading();
    const scope: any = this;
    loader.showLoading();


    let headers: any = {
      responseType: "json",
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    let auth = storage.getSync<any>(storage.AUTH);
    if (auth) {
      headers.Authorization = `Bearer ${auth["access_token"]}`;
    }
    this.options = {
      serverSide: true,
      searching: !this.isSearching,
      ajax: {
        url: this.sensorBloc.base_url,
        method: 'GET',
        dataType: 'json',
        dataFilter: (data) => {
          const parsedData = JSON.parse(data);
          return JSON.stringify(parsedData.data);
        },
        headers,
      },
      columnDefs: [
        {
          "targets": -1,
          "width": "1%"
        },
      ],
      columns: [
        { data: 'client_name' },
        { data: 'site_name' },
        { data: 'equipment_name' },
        { data: 'name' },
        {
          title: "Action",
          data: "id",
          render: function (id: any, type: any, full: any, meta: any) {
            return (
              '<div class="form-group" style="margin: auto;">' +
              '<div class="btn-group" role="group">' +
              `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye" data-placement="top" title="View" ></i></button>` +
              `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" data-placement="top" title="Edit"></i></button>` +
              `<button type="button" class="btn btn-danger btn-sm delete"id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
              "</div>" +
              "</div>"
            );
          }
        }
      ],
      createdRow: function (row: any, data: any) {
        $(row).on("click", "#viewAction", scope.viewData.bind(this, data, scope));
        $(row).on("click", "#editAction", scope.editData.bind(this, data, scope));
        $(row).on("click", "#deleteAction", scope.deleteData.bind(this, data, scope));
      },
      initComplete: async function (settings, json) {
        loader.close();
      }
    };
  }

  dtInstance(dt) {
    this.dt = dt;
  }

  openSearch() {
    this.isSearching = !this.isSearching;
    this.builDataTable();
  }

  async searching() {
    this.isSearching = true;
    let data: any = {
      client_id: this.sensor.client_id,
      site_id: this.sensor.site_id,
      equipment_id: this.sensor.equipment_id,
      isSearching: this.isSearching
    }
    await storage.set<any>(storage.SENSOR_ADV_SEARCH, data);
    const searchUrl = await this.sensorBloc.searchParams(this.sensor);

    this.dt.ajax.url(searchUrl);
    this.dt.ajax.reload();
  }

  async getAdvanceSearch() {
    let advSearchData: any = await storage.get<any>(storage.SENSOR_ADV_SEARCH);
    try {
      this.isSearching = advSearchData.isSearching;
    } catch (error) { }
    this.adv = advSearchData;
  }

  async getByEquipment() {
    this.equipment = {
      "equipment_id": this.$route.params.id
    }
  }

  async viewData(data: any) {
    this.$router.push(`/sensor/${data.id}`);
  }

  async editData(data: any) {
    this.$router.push(`/sensor/${data.id}/edit`);
  }

  async deleteData(data: any) {
    widget.alertDelete().then(async (result) => {
      try {
        if (!result.isConfirmed) {
          widget.alertSuccess('Deletion abort.', 'Your data is save.')
          return;
        }
        let message = await this.sensorBloc.destroySensor(data.id);
        widget.alertSuccess('Good Job!', 'Your data has been deleted.');
        this.builDataTable();
      } catch (error) {
        widget.alertError(error);
      }
    })
  }
}