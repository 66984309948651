import { Client, clientApi } from "./index"
import environment from "@/config"
import { helper } from "@cems-eco/core/src/utils/helper"

export function clientFactory() {
    const module = 'client';
    const api = clientApi;
    const base_url = environment.API_URL + '/' + module;

    async function getClients() : Promise<[Client]> {
        let response = await api.all()
        return response.data.data;
    }

    async function getClient(id: any): Promise<Client> {
        let response = await api.first(id)
        return response.data;
    }

    async function createClient(client: Client): Promise<Client> {
        let response = await api.create(client)
        return response.data;
    }

    async function updateClient(client: Client): Promise<Client> {
        let response = await api.edit(client.id, client)
        return response.data;
    }

    // FIXME: not working
    async function destroyClient(id: any): Promise<void> {
        let response = await api.destroy(id)
        return response.data;
    }

    async function searchParams(client: Client): Promise<string> {
        const uri = helper.toSerializeDatatable(client);
        return base_url + encodeURI(uri);
    }

    return {
        api,
        base_url,
        getClients,
        getClient,
        createClient,
        updateClient,
        destroyClient,
        searchParams,
    }

}

