import { Vue, setup } from "vue-class-component";
import { widget } from "@cems-eco/core/src/utils/widget";
import { Equipment, equipmentFactory } from "../../../equipment/services";
import $ from "jquery";
import environment from "@/config";
import storage from "@/storage";
export default class EquipmentViewComponent extends Vue {
  equipmentBloc = setup(() => equipmentFactory())
  equipments: object[] = []
  options = {}
  events: object[] = []
  equipment = new Equipment
  isSearching = false;
  dt: any;
  site: any;

  mounted() {
    this.getBySite();
    this.builDataTable()
  }

  async builDataTable() {
    const loader: any = await widget.loading();
    const scope: any = this;
    loader.showLoading();


    let headers: any = {
      responseType: "json",
      'Content-Type': 'application/json',
      'Accept': 'application/json',
  };

  let auth = storage.getSync<any>(storage.AUTH);
  if (auth) {
    headers.Authorization = `Bearer ${auth["access_token"]}`;
  }

    const searchUrl = await this.equipmentBloc.searchParams(this.site);
    this.options = {
      serverSide: true,
      searching: !this.isSearching,
      ajax: {
        url: searchUrl,
        method: 'GET',
        dataType: 'json',
        dataFilter: (data) => {
          const parsedData = JSON.parse(data);
          return JSON.stringify(parsedData.data);
        },
        headers,
      },
      columnDefs: [
        {
          "targets": -1,
          "width": "1%"
        },
      ],
      columns: [
        { data: 'client_name' },
        { data: 'site_name' },
        { data: 'name' },
        {
          title: "Action",
          data: "id",
          render: function (id: any, type: any, full: any, meta: any) {
            return (
              '<div class="form-group" style="margin: auto;">' +
              '<div class="btn-group" role="group">' +
              `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye" data-placement="top" title="View" ></i></button>` +
              `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" data-placement="top" title="Edit"></i></button>` +
              `<button type="button" class="btn btn-danger btn-sm delete"id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
              "</div>" +
              "</div>"
            );
          }
        }
      ],
      createdRow: function (row: any, data: any) {
        $(row).on("click", "#viewAction", scope.viewData.bind(this, data, scope));
        $(row).on("click", "#editAction", scope.editData.bind(this, data, scope));
        $(row).on("click", "#deleteAction", scope.deleteData.bind(this, data, scope));
      },
      initComplete: async function (settings, json) {
          const searchUrl = await scope.equipmentBloc.searchParams(scope.site);
          scope.dt.ajax.url(searchUrl);
          scope.dt.ajax.reload();
      }
    };
    setTimeout(function () { loader.close(); }, 2500);
  }

  dtInstance(dt) {
    this.dt = dt;
  }

  async viewData(data: any) {
    this.$router.push(`/equipment/${data.id}`);
  }

  async editData(data: any) {
    this.$router.push(`/equipment/${data.id}/edit`);
  }

  async deleteData(data: any) {
    widget.alertDelete().then(async (result) => {
      try {
        if (!result.isConfirmed) {
          widget.alertSuccess('Deletion abort.', 'Your data is save.')
          return;
        }
        let message = await this.equipmentBloc.destroyEquipment(data.id);
        widget.alertSuccess('Good Job!', 'Your data has been deleted.');
        this.builDataTable();
      } catch (error) {
        widget.alertError(error);
      }
    })
  }

  async getBySite() {
    this.site = {
      "site_id": this.$route.params.id
    }
  }
}