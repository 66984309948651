import { Vue, setup } from 'vue-class-component';
import { Sensor, sensorFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';

export default class ViewSensorPage extends Vue {
    sensorBloc = setup(() => sensorFactory())
    sensor = new Sensor
    clientOptions: any = {
        placeholder: '',
        disabled: true,
        ajax: {
            url: environment.API_URL + '/client/select2',
            processResults: function (data) {
                return {
                    results: data.data
                };
            },
        },
        data: []
    };
    siteOptions: any = {
        placeholder: '',
        disabled: true,
        ajax: {
            url: environment.API_URL + '/site/select2',
            processResults: function (data) {
                return {
                    results: data.data
                };
            },
        },
        data: []
    };
    equipmentOptions: any = {
        placeholder: '',
        disabled: true,
        ajax: {
            url: environment.API_URL + '/equipment/select2',
            processResults: function (data) {
                return {
                    results: data.data
                };
            },
        },
        data: []
    };
    alarmRules: Array<any> = []

    async created() {
        const loader: any = await widget.loading();
        loader.showLoading();
        this.sensor.id = this.$route.params.id;
        this.sensor = await this.sensorBloc.getSensor(this.sensor.id);
        this.alarmRules = this.sensor.alarm_rules;
        this.getClient();
        this.getSite();
        this.getEquipment();
        loader.close();
    }

    async getClient() {
        const client = await this.sensorBloc.getClient(this.sensor.client_id);

        if (client) this.updateClientOption([{ ...client, selected: true, text: client.name }]);
    }

    async getSite() {
        const site = await this.sensorBloc.getSite(this.sensor.site_id);

        if (site) this.updateSiteOption([{ ...site, selected: true, text: site.name }]);
    }

    async getEquipment() {
        const equipment = await this.sensorBloc.getEquipment(this.sensor.equipment_id);

        if (equipment) this.updateEquipmentOption([{ ...equipment, selected: true, text: equipment.name }]);
    }

    updateClientOption(data: any[]) {
        this.clientOptions = {
            ...this.clientOptions,
            data: data,
        }
    }

    updateSiteOption(data: any[]) {
        this.siteOptions = {
            ...this.siteOptions,
            data: data,
        }
    }

    updateEquipmentOption(data: any[]) {
        this.equipmentOptions = {
            ...this.equipmentOptions,
            data: data,
        }
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.');
                    return;
                }
                let message = await this.sensorBloc.deleteRelation(this.sensor.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.$router.replace('/sensor');
            } catch (error) {
                widget.alertError(error);
            }
        })
    }
}
