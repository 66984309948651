import { Vue, setup } from "vue-class-component";
import { widget } from "@cems-eco/core/src/utils/widget";
import { Client, clientFactory } from "../services";
import $ from "jquery";
import environment from "@/config";
import storage from "@/storage";

export default class ClientPage extends Vue {

    clientBloc = setup(() => clientFactory())
    clients: object[] = [];
    options = {};
    events: object[] = [];
    client = new Client
    dt: any;
    isSearching = true;
    statusOptions: any = {
        placeholder: '-- Please select --',
        allowClear: true,
        data: []
    };

    created(): void {
        this.initOption();
    }

    mounted() {
        this.builDataTable()
    }

    initOption() {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        this.statusOptions = {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url: environment.API_URL + '/reference/select2/user-status',
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    async builDataTable() {
        const loader: any = await widget.loading();
        const scope: any = this;
        loader.showLoading();

        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            console.log("auth", auth)
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }
        this.options = {
            serverSide: true,
            ajax: {
                url: this.clientBloc.base_url,
                method: 'GET',
                dataType: 'json',
                dataFilter: (data) => {
                    const parsedData = JSON.parse(data);
                    return JSON.stringify(parsedData.data);
                },
                headers,
            },
            columnDefs: [
                {
                    "targets": -1,
                    "width": "1%"
                },
            ],
            mobileView: {
                header: {
                    actions: [
                        {
                            id: 'viewAction',
                            template: '<i class="far fa-eye text-success" title="View" ></i> View',
                        },
                        {
                            id: 'editAction',
                            template: '<i class="fas fa-edit text-primary" title="View" ></i> Update',
                        },
                        {
                            id: 'deleteAction',
                            template: '<i class="far fa-trash-alt text-danger" title="Delete" ></i> Delete',
                        },
                        {
                            id: 'duplicateAction',
                            template: '<i class="far fa-regular fa-copy text-warning" title="Duplicate" ></i> Duplicate',
                        },
                    ],
                    title: function (data: any) {
                        return data.name;
                    },
                }
            },
            columns: [
                {
                    data: 'name',
                    title: 'Name',
                    mobileView: true
                },
                {
                    data: 'status',
                    title: 'Status',
                    mobileView: true
                },
                {
                    title: "Action",
                    data: "id",
                    render: function (id: any, type: any, full: any, meta: any) {
                        // console.log(full);

                        return (
                            '<div class="form-group" style="margin: auto;">' +
                            '<div class="btn-group" role="group">' +
                            `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye"  ></i></button>` +
                            `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" ></i></button>` +
                            `<button type="button" class="btn btn-danger btn-sm delete" id="deleteAction"><i class="far fa-trash-alt" ></i></button>` +
                            `<button type="button" class="btn btn-warning btn-sm duplicate" id="duplicateAction"><i class="far fa-regular fa-copy" ></i></button>` +
                            "</div>" +
                            "</div>"
                        );
                    }
                }
            ],
            createdRow: function (row: any, data: any) {
                $(row).on("click", "#viewAction", scope.viewData.bind(this, data, scope));
                $(row).on("click", "#editAction", scope.editData.bind(this, data, scope));
                $(row).on("click", "#deleteAction", scope.deleteData.bind(this, data, scope));
                $(row).on("click", "#duplicateAction", scope.duplicateData.bind(this, data, scope));
            }
        };
        loader.close();
    }

    dtInstance(dt) {
        this.dt = dt;
    }

    openSearch() {
        this.isSearching = !this.isSearching;
        this.builDataTable();
    }

    async searching() {
        const searchUrl = await this.clientBloc.searchParams(this.client);
        this.dt.ajax.url(searchUrl);
        this.dt.ajax.reload();
    }

    async viewData(data: any) {
        this.$router.push(`/client/${data.id}`);
    }

    async duplicateData(data: any) {
        this.$router.push(`/client/create?duplicate=${data.id}`);
    }

    async editData(data: any) {
        this.$router.push(`/client/${data.id}/edit`);
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.')
                    return;
                }
                let message = await this.clientBloc.destroyClient(data.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.builDataTable();
            } catch (error) {
                widget.alertError(error);
            }
        })
    }

    statusSelectChanged(data: any[]) {
        this.client.status = data[0].text;
    }

}

