import { Equipment, equipmentApi } from "./index"
import { ref } from "vue"
import environment from "@/config"
import { helper } from "@cems-eco/core/src/utils/helper"
import { Site, siteApi } from "../../site/services"
import { Client, clientApi } from "../../client/services"

export function equipmentFactory() {
    const module = 'equipment';
    const api = equipmentApi;
    const context = ref([]);
    const base_url = environment.API_URL + '/' + module;

    async function getEquipments() : Promise<[Equipment]> {
        let response = await api.all()
        return response.data.data;
    }

    async function getEquipment(id: any): Promise<Equipment> {
        let response = await api.first(id)
        return response.data;
    }

    async function createEquipment(equipment: Equipment): Promise<Equipment> {
        let response = await api.create(equipment)
        return response.data;
    }

    async function updateEquipment(equipment: Equipment): Promise<Equipment> {
        let response = await api.edit(equipment.id, equipment)
        return response.data;
    }

    // FIXME: not working
    async function destroyEquipment(id: any): Promise<void> {
        let response = await api.destroy(id)
        return response.data;
    }

    async function searchParams(equipment: Equipment): Promise<string> {
        const uri = helper.toSerializeDatatable(equipment)
        return base_url + encodeURI(uri);
    }

    async function getClient(id: any): Promise<Client> {
        let response = await clientApi.first(id)
        return response.data;
    }

    async function getSite(id: any): Promise<Site> {
        let response = await siteApi.first(id)
        return response.data;
    }

    return {
        api,
        base_url,
        getEquipments,
        getEquipment,
        createEquipment,
        updateEquipment,
        destroyEquipment,
        searchParams,
        getClient,
        getSite,
    }

}

