import InformationViewComponent from "./information-view/information-view.component.vue";
import InformationEditComponent from "./information-edit/information-edit.component.vue";
import ManagementViewComponent from "./management-view/management-view.component.vue";
import ManagementEditComponent from "./management-edit/management-edit.component.vue";
import SiteViewComponent from "./site-view/site-view.component.vue";
import SensorViewComponent from "./sensor-view/sensor-view.component.vue";
import EquipmentViewComponent from "./equipment-view/equipment-view.component.vue";

export default {
    InformationViewComponent,
    InformationEditComponent,
    ManagementViewComponent,
    ManagementEditComponent,
    SiteViewComponent,
    SensorViewComponent,
    EquipmentViewComponent
}