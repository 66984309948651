import { Vue, setup, Options } from 'vue-class-component';
import { Permission, User, UserDetail, userFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import component from '../components/components';
import environment from '@/config';

@Options({
    components: component,
})

export default class ViewUserPage extends Vue {
    userBloc = setup(() => userFactory())
    options: any = {
        placeholder: '-- Please select --',
        allowClear: false,
        multiple: true,
        disabled: true,
        ajax: {
            url: environment.API_URL + '/client/select2',
            processResults: function (data) {
                return {
                    results: data.data
                };
            },
        },
        data: []
    };
    userDetail = new UserDetail
    permissions: Permission[] = []

    async created() {
        this.userDetail.user = new User;
        this.userDetail.user.id = this.$route.params.id;
    }

    async mounted() {
        const loader: any = await widget.loading();
        loader.showLoading();
        this.userDetail = await this.userBloc.getUserDetail(this.userDetail.user.id);
        this.permissions = this.userDetail.permissions;
        const clients = await this.userBloc.getClients();
        const userClient = clients.filter((c) => this.userDetail.clients.includes(c.id));
        loader.close();
        if (userClient) this.updateOption(userClient.map((c) => ({ ...c, selected: true })));
    }

    updateOption(data: any[]) {
        this.options = {
            ...this.options,
            data: data,
        }
    }

    selectChanged(data: any[]) {
        if (data) data = data.map((d) => ({ ...d, selected: true }))
        this.updateOption(data);
        this.userDetail.clients = data.map((d) => d.id);
    }

    selectedPermission(data: Permission[]) {
        this.userDetail.permissions = data;
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.');
                    return;
                }
                let message = await this.userBloc.destroyUser(this.userDetail.user.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.$router.replace('/user');
            } catch (error) {
                widget.alertError(error);
            }
        })
    }
}
