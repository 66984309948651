import { Vue, setup } from 'vue-class-component';
import { Management, managementFactory } from "@modules/management/services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';
export default class ManagementEditComponent extends Vue {
  managementBloc = setup(() => managementFactory())
  management = new Management
  clientOptions: any = {
    placeholder: '',
    allowClear: true,
    disabled: true,
    ajax: {
      url: environment.API_URL + '/client/select2',
      processResults: function (data) {
        return {
          results: data.data
        };
      },
    },
  };

  async created() {
    const loader: any = await widget.loading();
    loader.showLoading();

    await this.getMangement();

    loader.close();
  }

  mounted() {
    widget.datePicker('edit-management-contract_expiry');
    widget.datePicker('edit-management-installation_date');
  }

  private async getMangement() {
    const clientId = this.$route.params.id as string;
    this.getClient(clientId);
    this.management = await this.managementBloc.getManagementByClient(clientId);
    
  }

  async getClient(id: string) {
    this.management.client_id = id
    const client = await this.managementBloc.getClient(id);


    if (client) this.updateClientOption([{ ...client, selected: true, text: client.name }]);
  }

  updateClientOption(data: any[]) {
    this.clientOptions = {
      ...this.clientOptions,
      data: data,
    }
  }

  async save() {
    try {
      this.management = await this.managementBloc.updateManagement(this.management);
      widget.alertSuccess('Good Job!', 'You have successfully edit this Management');
      this.$router.push(`/client/${this.management.client_id}`)
    } catch (error) {
      widget.alertError(error);
    }
  }

  picker(id: string) {
    widget.datePicker(id)
  }

  clientSelectChanged(data: any[]) {
    this.management.client_id = data[0].id;
  }
}