import { Vue, setup } from "vue-class-component";
import { Client, clientFactory } from "../../services";
import { widget } from "@cems-eco/core/src/utils/widget";

export default class InformationViewComponent extends Vue {
  clientBloc = setup(() => clientFactory())
  client = new Client

  async created() {
    const loader: any = await widget.loading();
    loader.showLoading();
    this.client.id = this.$route.params.id;
    this.client = await this.clientBloc.getClient(this.client.id);
    loader.close();
  }

}