import { Vue, setup } from 'vue-class-component';
import { Equipment, equipmentFactory } from "../../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';

export default class InformationViewComponent extends Vue {
    equipmentBloc = setup(() => equipmentFactory())
    equipment = new Equipment
    clientOptions: any = {
        placeholder: '',
        disabled: true,
        ajax: {
            url: environment.API_URL + '/client/select2',
            processResults: function (data) {
                return {
                    results: data.data
                };
            },
        },
        data: []
    };
    siteOptions: any = {
        placeholder: '',
        disabled: true,
        ajax: {
            url: environment.API_URL + '/site/select2',
            processResults: function (data) {
                return {
                    results: data.data
                };
            },
        },
        data: []
    };

    async created() {
        const loader: any = await widget.loading();
        loader.showLoading();
        this.equipment.id = this.$route.params.id;
        this.equipment = await this.equipmentBloc.getEquipment(this.equipment.id);
        this.getClient();
        this.getSite();
        loader.close();
    }

    async getClient() {
        const client = await this.equipmentBloc.getClient(this.equipment.client_id);

        if (client) this.updateClientOption([{ ...client, selected: true, text: client.name }]);
    }

    async getSite() {
        const site = await this.equipmentBloc.getSite(this.equipment.site_id);

        if (site) this.updateSiteOption([{ ...site, selected: true, text: site.name }]);
    }

    updateClientOption(data: any[]) {
        this.clientOptions = {
            ...this.clientOptions,
            data: data,
        }
    }

    updateSiteOption(data: any[]) {
        this.siteOptions = {
            ...this.siteOptions,
            data: data,
        }
    }

}