import { Vue, setup } from 'vue-class-component';
import { Site, siteFactory } from "../../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';

export default class InformationViewComponent extends Vue {
  siteBloc = setup(() => siteFactory())
    site = new Site
    clientOptions: any = {
        placeholder: '',
        disabled: true,
        ajax: {
            url: environment.API_URL + '/client/select2',
            processResults: function (data) {
                return {
                    results: data.data
                };
            },
        },
        data: []
    };

    async created() {
        const loader: any = await widget.loading();
        loader.showLoading();
        this.site.id = this.$route.params.id;
        this.site = await this.siteBloc.getSite(this.site.id);
        this.getClient();
        loader.close();
    }

    async getClient() {
        const client = await this.siteBloc.getClient(this.site.client_id);

        if (client) this.updateClientOption([{ ...client, selected: true, text: client.name }]);
    }

    updateClientOption(data: any[]) {
        this.clientOptions = {
            ...this.clientOptions,
            data: data,
        }
    }

    clientSelectChanged(data: any[]) {
        this.site.client_id = data[0].id;
    }

}