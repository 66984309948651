import { Management, managementApi } from "./index"
import { Observable } from "rxjs"
import { ISiteStorage } from "@/storage/model"
import storage from "@/storage"
import { startWith } from "rxjs/operators"
import { Client, clientApi } from "../../client/services"
import { api as apiNet } from "@cems-eco/core/src/services/api"

export function managementFactory() {
    const module = 'management';
    const api = managementApi;

    async function getManagements() : Promise<[Management]> {
        let response = await api.all()
        return response.data.data;
    }

    async function getManagement(id: any): Promise<Management> {
        let response = await api.first(id)
        return response.data;
    }

    async function getManagementByClient(id: any): Promise<Management> {
        let response = await api.getManagementByClient(id)
        return response.data;
    }

    async function createManagement(management: Management): Promise<Management> {
        let response = await api.create(management)
        return response.data;
    }

    async function updateManagement(management: Management): Promise<Management> {
        let response = await api.edit(management.id, management)
        return response.data;
    }

    // FIXME: not working
    async function destroyManagement(id: any): Promise<void> {
        let response = await api.destroy(id)
        return response.data;
    }

    async function getClient(id: any): Promise<Client> {
        let response = await clientApi.first(id)
        return response.data;
    }


    async function getSiteStorage(): Promise<Observable<ISiteStorage>> {
        const siteStorage: ISiteStorage = await storage.get<ISiteStorage>(storage.SITE);
        return storage.observable<ISiteStorage>(storage.SITE).pipe(startWith(siteStorage));
    }

    async function getClientSelect2Url(): Promise<string> {
        return await apiNet.getUrl('/client/select2');
    }

    return {
        api,
        getManagements,
        getManagement,
        getManagementByClient,
        createManagement,
        updateManagement,
        destroyManagement,
        getClient,
        getSiteStorage,
        getClientSelect2Url,
    }

}

