import { Vue, Options, setup } from 'vue-class-component';
import { Tab } from '@cems-eco/core/src/models';
import { Equipment, equipmentFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import component from "../components/component";
@Options({
    components: component
})
export default class ViewEquipmentPage extends Vue {
    equipmentBloc = setup(() => equipmentFactory())
    tabs: Tab
    activeTabIndex: number = 0;
    equipment = new Equipment

    async created() {
        this.equipment.id = this.$route.params.id;
        this.setTabs();
    }

    setTabs() {
        this.tabs = new Tab
        this.tabs.list = [
            {
                id: 'information',
                name: 'Information',
                icon: 'info',
                active: true,
                component: 'InformationViewComponent'
            },
            {
                id: 'sensor',
                name: 'Sensor',
                icon: 'wave-square',
                active: false,
                component: 'SensorViewComponent'
            }
        ];
    }

    onTabChanged(index: number) {
        this.activeTabIndex = index;
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.');
                    return;
                }
                let message = await this.equipmentBloc.destroyEquipment(this.equipment.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.$router.replace('/equipment');
            } catch (error) {
                widget.alertError(error);
            }
        })
    }
}
