import { Client } from "../../client/services";

export interface ISite {
    readonly id: any;
    name: string
    description: string
}

export class Site {
    public id!: any;
    public _method!: string;
	public client_id!: string;
	public name!: string;
	public additional!: string;
    public host!: string;
    public subscribed!: string;
 
}