import { Vue, setup, Options } from 'vue-class-component';
import { User, userFactory, UserDetail, Permission } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';
import component from '../components/components';
import { Validators } from '@cems-eco/core/src/utils/validator';
import storage from "@/storage";


@Options({
    components: component,
})
export default class CreateUserPage extends Vue {
    userBloc = setup(() => userFactory())
    permissions: Permission[] = [];
    roleOptions: any = {
        placeholder: '-- Please select --',
        allowClear: true,
        data: []
    };
    clientOptions: any = {
        placeholder: '-- Please select --',
        allowClear: true,
        multiple: true,
        data: []
    };
    userDetail = new UserDetail
    rules: any = {
        name: { type: "string", min: 5 },
        email: { type: "email" },
        password: { type: "string", min: 1, },
        confirmPassword: { type: "equal", min: 1, field: "password" },
        role: { type: "string" },
        status: { type: "string" },
    };
    user = new Validators<User>({
        value: new User,
        rules: this.rules
    });

    created() {
        this.userDetail.user = new User
        this.roleOptions = this.initOption(environment.API_URL + '/reference/select2/user-position');
        this.clientOptions = {...this.initOption(environment.API_URL + '/client/select2/all'), multiple: true};
    }

    initOption(endpoint: string) {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        return {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url: endpoint,
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    updateRoleOption(data: any[]) {
        this.roleOptions = {
            ...this.roleOptions,
            data: data,
        }
    }

    updateClientOption(data: any[]) {
        this.clientOptions = {
            ...this.clientOptions,
            data: data,
        }
    }

    roleSelectChanged(data: any[]) {
        if (data) data = data.map((d) => ({ ...d, selected: true }))
        this.updateRoleOption(data);
        this.user.value.role = data[0].text;
    }

    clientSelectChanged(data: any[]) {
        if (data) data = data.map((d) => ({ ...d, selected: true }))
        this.updateClientOption(data);
        this.userDetail.clients = data.map((d) => d.id);
    }

    selectedPermission(data: Permission[]) {
        console.log(data);
        this.userDetail.permissions = data;
    }

    async save() {
        try {
            this.userDetail.user = this.user.value;
            this.userDetail = await this.userBloc.createUserDetail(this.userDetail);

            widget.alertSuccess('Good Job!', 'You have successfully created a User');
            this.$router.push(`/user/${this.userDetail.user.id}`)
        } catch (e) {
            const error: any = e;
            widget.alert("Oops", error.errors.email[0], "error");
        }
    }
}

