import { Vue, setup } from 'vue-class-component';
import { Equipment, equipmentFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';
import storage from "@/storage";

export default class EditEquipmentPage extends Vue {
    title: string = 'Equipment';
    equipmentBloc = setup(() => equipmentFactory())
    equipment = new Equipment
    clientOptions: any = {
        placeholder: '',
        allowClear: true,
        data: [],
    };
    siteOptions: any = {
        placeholder: '',
        allowClear: true,
        disabled: true,
        data: [],
    };

    async created() {
        const loader: any = await widget.loading();
        loader.showLoading();
        this.clientOptions = this.initOption(environment.API_URL + '/client/select2');
        this.siteOptions = { disabled: true, ...this.initOption(environment.API_URL + '/site/select2') };
        this.equipment.id = this.$route.params.id
        this.equipment = await this.equipmentBloc.getEquipment(this.equipment.id);
        this.title = this.equipment.name;
        this.getClient();
        this.getSite();
        loader.close();
    }

    mounted() {
        widget.datePicker('edit-equipment-date-manufactured');
    }
    initOption(endpoint: string) {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        return {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url: endpoint,
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    async getClient() {
        const client = await this.equipmentBloc.getClient(this.equipment.client_id);

        if (client) this.updateClientOption([{ ...client, selected: true, text: client.name }]);
    }

    async getSite() {
        const site = await this.equipmentBloc.getSite(this.equipment.site_id);

        if (site) this.updateSiteOption([{ ...site, selected: true, text: site.name }]);
    }

    updateClientOption(data: any[]) {
        this.clientOptions = {
            ...this.clientOptions,
            data: data,
            disabled: data.length == 0,
        }
    }

    updateSiteOption(data: any[]) {
        this.siteOptions = {
            ...this.siteOptions,
            data: data,
            disabled: data.length == 0,
        }
    }

    async save() {
        try {
            // FIXME: method spoofing should overide from base api
            this.equipment = await this.equipmentBloc.updateEquipment(this.equipment);
            widget.alertSuccess('Good Job!', 'You have successfully edit this Equipment');
            this.$router.push(`/equipment/${this.equipment.id}`)
        } catch (error) {
            widget.alertError(error);
        }
    }

    clientSelectChanged(data: any[]) {
        this.equipment.client_id = data[0].id;
        const isDisabled = this.equipment.client_id == undefined || this.equipment.client_id == '';
        this.siteOptions.disabled = isDisabled;
        if (isDisabled) this.siteOptions.data = []
        this.siteOptions.ajax.url = environment.API_URL + '/site/select2/client/' + this.equipment.client_id;
    }

    siteSelectChanged(data: any[]) {
        this.equipment.site_id = data[0].id;
    }
}

