import { Vue, setup } from 'vue-class-component';
import { Site, siteFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';
import storage from '@/storage';

export default class EditSitePage extends Vue {
    siteBloc = setup(() => siteFactory())
    site = new Site
    clientOptions: any = {
        placeholder: '-- Please select --',
        allowClear: true,
        data: []
    };

    async created() {
        const loader: any = await widget.loading();
        loader.showLoading();
        this.initOption();
        this.site.id = this.$route.params.id;
        this.site = await this.siteBloc.getSite(this.site.id);
        this.getClient();
        loader.close();
    }

    initOption() {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        this.clientOptions = {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url: environment.API_URL + '/client/select2',
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    async getClient() {
        const client = await this.siteBloc.getClient(this.site.client_id);

        if (client) this.updateClientOption([{ ...client, selected: true, text: client.name }]);
    }

    updateClientOption(data: any[]) {
        this.clientOptions = {
            ...this.clientOptions,
            data: data,
        }
    }

    async save() {
        try {
            // FIXME: method spoofing should overide from base api
            this.site = await this.siteBloc.updateSite(this.site);
            widget.alertSuccess('Good Job!', 'You have successfully edit this Site');
            this.$router.push(`/site/${this.site.id}`)
        } catch (error) {
            widget.alertError(error);
        }
    }

    clientSelectChanged(data: any[]) {
        this.site.client_id = data[0].id;
    }
}

