import { Vue, setup, Options } from 'vue-class-component';
import { User, userFactory, UserDetail, Permission } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import component from '../components/components';
import environment from '@/config';
import { Validators } from '@cems-eco/core/src/utils/validator';

@Options({
    components: component,
})
export default class EditUserPage extends Vue {
    userBloc = setup(() => userFactory())
    roleOptions: any = {
        placeholder: '',
        allowClear: true,
        ajax: {
            url: environment.API_URL + '/reference/select2/user-position',
            processResults: function (data) {
                return {
                    results: data.data
                };
            },
        },
        data: []
    };
    clientOptions: any = {
        placeholder: '-- Please select --',
        allowClear: true,
        multiple: true,
        ajax: {
            url: environment.API_URL + '/client/select2/all',
            processResults: function (data) {
                return {
                    results: data.data
                };
            },
        },
        data: []
    };
    userDetail = new UserDetail
    rules: any = {
        name: { type: "string", min: 5 },
        email: { type: "email" },
        password: { type: "string", min: 1, },
        confirmPassword: { type: "equal", min: 1, field: "password" },
        role: { type: "string" },
        status: { type: "string" },
    };
    user = new Validators<User>({
        value: new User,
        rules: this.rules
    });
    permissions: Permission[] = []

    async created() {
        const loader: any = await widget.loading();
        loader.showLoading();
        this.userDetail.user = new User;
        loader.close();
    }

    async mounted() {
        this.userDetail = await this.userBloc.getUserDetail(<string>this.$route.params.id);
        this.user.value = this.userDetail.user;
        this.permissions = this.userDetail.permissions;

        const clients = await this.userBloc.getClients();
        const userClient = clients.filter((c) => this.userDetail.clients.includes(c.id));
        if (userClient) this.updateClientOption(userClient.map((c) => ({ ...c, selected: true })));

        if (this.userDetail.user.role) this.updateRoleOption([{ id: this.userDetail.user.role, text: this.userDetail.user.role, selected: true }]);
    }

    updateRoleOption(data: any[]) {
        this.roleOptions = {
            ...this.roleOptions,
            data: data,
        }
    }

    updateClientOption(data: any[]) {
        this.clientOptions = {
            ...this.clientOptions,
            data: data,
        }
    }

    roleSelectChanged(data: any[]) {
        if (data) data = data.map((d) => ({ ...d, selected: true }))
        this.updateRoleOption(data);
        this.user.value.role = data[0].text;
    }

    clientSelectChanged(data: any[]) {
        if (data) data = data.map((d) => ({ ...d, selected: true }))
        this.updateClientOption(data);
        this.userDetail.clients = data.map((d) => d.id);
    }

    selectedPermission(data: Permission[]) {
        this.userDetail.permissions = data;
    }

    async save() {
        try {
            this.userDetail.user = { ...this.user.value, id: this.userDetail.user.id };
            this.userDetail = await this.userBloc.updateUserDetail(this.userDetail);
            widget.alertSuccess('Good Job!', 'You have successfully edit this User');
            this.$router.push(`/user/${this.userDetail.user.id}`)
        } catch (error) {
            widget.alertError(error);
        }
    }
}

