export interface ISensor {
    readonly id: any;
    name: string
    description: string
}

export class Sensor {
    public id!: any;
    public _method!: string;
	public client_id!: string;
	public site_id!: string;
	public equipment_id!: string;
	public name!: string;
	public min_threshold!: string;
	public max_threshold!: string;
	public unit!: string;
	public formula!: string;
	public additional!: string;
    public alarm_rules!: Array<AlarmRules>;
}

export class AlarmRules {
    public rule_type: string;
    public reading_value_from: number;
    public reading_value_to: number;
    public rule_tag: string;
    public alarm_popup: string;
}