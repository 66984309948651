import { api } from '@cems-eco/core/src/services/api';

export class ManagementApi {
    private BASE_URL: string;

    
    constructor() {
        this.BASE_URL = "/management";
    }

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async getManagementByClient(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/detail/${id}`, true);
    }

    async create(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async edit(id: any, data: any): Promise<any> {
        return await api.put<any>(`${this.BASE_URL}/${id}`, data, true);
    }

    async destroy(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_URL}/${id}`, true);
    }

}

const managementApi = new ManagementApi();
export { managementApi };
