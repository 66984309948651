export interface IUser {
    readonly id: any;
    name: string
    description: string
}

export class User {
    public id!: any;
    public _method!: string;
	public name!: string;
	public email!: string;
	public role!: string;
	public password!: string;
	public confirmPassword!: string;
	public additional!: string;
}

export interface IPermissionView {
    name: string;
    method: number;
    selected: boolean;
}

export class PermissionView {
    constructor(data: Partial<PermissionView>){
        Object.assign(this, data);
    }
    name: string;
    permission: IPermissionView[];
    methods: string[];
}

export class Permission {
    constructor(data: Partial<PermissionView>){
        Object.assign(this, data);
    }
    name: string;
    value: string;
}

export class UserDetail {
    user: User;
    permissions: Permission[];
    clients: string[];
}