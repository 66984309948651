import { Sensor, sensorApi } from "./index"
import { ref } from "vue"
import environment from "@/config"
import { helper } from "@cems-eco/core/src/utils/helper"
import { Equipment, equipmentApi } from "../../equipment/services";
import { Client, clientApi } from "../../client/services";
import { Site, siteApi } from "../../site/services";

export function sensorFactory() {
    const module = 'sensor';
    const api = sensorApi;
    const context = ref([]);
    const base_url = environment.API_URL + '/' + module;

    async function getSensors() : Promise<[Sensor]> {
        let response = await api.all()
        return response.data.data;
    }

    async function getSensor(id: any): Promise<Sensor> {
        let response = await api.first(id)
        return response.data;
    }

    async function createSensor(sensor: Sensor): Promise<Sensor> {
        let response = await api.create(sensor)
        return response.data;
    }

    async function updateSensor(sensor: Sensor): Promise<Sensor> {
        let response = await api.edit(sensor.id, sensor)
        return response.data;
    }

    // FIXME: not working
    async function destroySensor(id: any): Promise<void> {
        let response = await api.destroy(id)
        return response.data;
    }

    async function searchParams(sensor: Sensor): Promise<string> {
        const uri = helper.toSerializeDatatable(sensor)
        return base_url + encodeURI(uri);
    }

    async function getClient(id: any): Promise<Client> {
        let response = await clientApi.first(id)
        return response.data;
    }

    async function getSite(id: any): Promise<Site> {
        let response = await siteApi.first(id)
        return response.data;
    }

    async function getEquipment(id: any): Promise<Equipment> {
        let response = await equipmentApi.first(id)
        return response.data;
    }

    async function storeRelation(sensor: Sensor): Promise<Sensor> {
        let response = await api.storeRelation(sensor)
        return response.data;
    }
    
    async function updateRelation(sensor: Sensor): Promise<Sensor> {
        let response = await api.updateRelation(sensor.id, sensor)
        return response.data;
    }

    async function deleteRelation(id: any): Promise<void> {
        let response = await api.deleteRelation(id)
        return response.data;
    }

    return {
        api,
        base_url,
        getSensors,
        getSensor,
        createSensor,
        updateSensor,
        destroySensor,
        searchParams,
        getClient,
        getSite,
        getEquipment,
        storeRelation,
        updateRelation,
        deleteRelation
    }

}

