import { api } from '@cems-eco/core/src/services/api';
import { Site } from './index';

export class SiteApi {
    private BASE_URL: string;

    
    constructor() {
        this.BASE_URL = "/site";
    }

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async create(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async edit(id: any, data: any): Promise<any> {
        return await api.put<any>(`${this.BASE_URL}/${id}`, data, true);
    }

    async destroy(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_URL}/${id}`, true);
    }

    async searchParams(data: any): Promise<any> {
        return api.toSerialize(data);
    }

}

const siteApi = new SiteApi();
export { siteApi };
