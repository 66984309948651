import { Vue, setup } from "vue-class-component";
import { widget } from "@cems-eco/core/src/utils/widget";
import { Client, clientFactory } from "../../services";
import environment from '@/config';
import storage from "@/storage";

export default class InformationEditComponent extends Vue {
  active = false;

  clientBloc = setup(() => clientFactory())
  client = new Client
  statusOptions: any = {
    placeholder: '-- Please select --',
    allowClear: true,
    data: []
  };

  async created() {
    this.initOption();
    const loader: any = await widget.loading();
    loader.showLoading();
    this.client.id = this.$route.params.id
    this.client = await this.clientBloc.getClient(this.client.id);
    if (this.client.status) this.updateStatusOption([{ id: this.client.status, text: this.client.status, selected: true }]);
    loader.close();
  }
  
  initOption() {
    let headers: any = {
      responseType: "json",
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    let auth = storage.getSync<any>(storage.AUTH);
    if (auth) {
      headers.Authorization = `Bearer ${auth["access_token"]}`;
    }

    this.statusOptions = {
      placeholder: '-- Please select --',
      allowClear: true,
      ajax: {
        url: environment.API_URL + '/reference/select2/user-status',
        headers,
        processResults: function (data) {
          return {
            results: data.data
          };
        },
      },
      data: []
    }
  }

  updateStatusOption(data: any[]) {
    this.statusOptions = {
      ...this.statusOptions,
      data: data,
    }
  }

  statusSelectChanged(data: any[]) {
    if (data) data = data.map((d) => ({ ...d, selected: true }))
    this.updateStatusOption(data);

    this.client.status = data[0].text;
  }

  async save() {
    try {
      // FIXME: method spoofing should overide from base api
      this.client = await this.clientBloc.updateClient(this.client);
      widget.alertSuccess('Good Job!', 'You have successfully edit this Client');
      this.$router.push(`/client/${this.client.id}`)
    } catch (error) {
      widget.alertError(error);
    }
  }

}