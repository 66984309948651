import { Vue, setup } from 'vue-class-component';
import { Client, clientFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';
import storage from "@/storage";

export default class CreateClientPage extends Vue {
  clientBloc = setup(() => clientFactory())
  client = new Client
  statusOptions: any = {
    placeholder: '-- Please select --',
    allowClear: true,
    data: []
  };

  async created() {
    const loader: any = await widget.loading();
    loader.showLoading();
    this.initOption();
    await this.duplicate();
    loader.close();
  }

  async duplicate() {
    
    if ("duplicate" in this.$route.query) {

      this.client = await this.clientBloc.getClient(this.$route.query.duplicate);
      this.client.id = "";
      if (this.client.status) {
        this.updateStatusOption([{ id: this.client.status, text: this.client.status, selected: true }]);
      }
    }

  }
  
  initOption() {
    let headers: any = {
      responseType: "json",
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    let auth = storage.getSync<any>(storage.AUTH);
    if (auth) {
      headers.Authorization = `Bearer ${auth["access_token"]}`;
    }

    this.statusOptions = {
      placeholder: '-- Please select --',
      allowClear: true,
      ajax: {
        url: environment.API_URL + '/reference/select2/user-status',
        headers,
        processResults: function (data) {
          return {
            results: data.data
          };
        },
      },
      data: []
    }
  }

  updateStatusOption(data: any[]) {
    console.log(data);

    this.statusOptions = {
      ...this.statusOptions,
      data: data,
    }

    console.log(this.statusOptions);
  }

  statusSelectChanged(data: any[]) {
    if (data) data = data.map((d) => ({ ...d, selected: true }))
    this.updateStatusOption(data);
    this.client.status = data[0].text;
  }

  async save() {
    try {
      this.client = await this.clientBloc.createClient(this.client);
      widget.alertSuccess('Good Job!', 'You have successfully created a Client');
      const confirm = await widget.presentAlertConfirm('Do you want to create a new Site for this Client?', '',);
      if (confirm) return this.$router.push(`/site/create/${this.client.id}`);
      this.$router.push(`/client/${this.client.id}`)
    } catch (error) {
      widget.alertError(error);
    }
  }
}

