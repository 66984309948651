import { Site, siteApi } from "./index"
import { ref } from "vue"
import environment from "@/config"
import { helper } from "@cems-eco/core/src/utils/helper"
import { Client, clientApi } from "../../client/services"

export function siteFactory() {
    const module = 'site';
    const api = siteApi;
    const context = ref([]);
    const base_url = environment.API_URL + '/' + module;

    async function getSites() : Promise<[Site]> {
        let response = await api.all()
        return response.data.data;
    }

    async function getSite(id: any): Promise<Site> {
        let response = await api.first(id)
        return response.data;
    }

    async function createSite(site: Site): Promise<Site> {
        let response = await api.create(site)
        return response.data;
    }

    async function updateSite(site: Site): Promise<Site> {
        let response = await api.edit(site.id, site)
        return response.data;
    }

    // FIXME: not working
    async function destroySite(id: any): Promise<void> {
        let response = await api.destroy(id)
        return response.data;
    }

    async function searchParams(site: Site): Promise<string> {
        const uri = helper.toSerializeDatatable(site);
        return base_url + encodeURI(uri);
    }

    async function getClient(id: any): Promise<Client> {
        let response = await clientApi.first(id)
        return response.data;
    }

    return {
        api,
        base_url,
        getSites,
        getSite,
        createSite,
        updateSite,
        destroySite,
        searchParams,
        getClient,
    }

}

