export interface IClient {
    readonly id: any;
    name: string
    description: string
}

export class Client {
    public id!: any;
    public _method!: string;
	public name!: string;
	public additional!: string;
	public status!: string;
	public contact!: string;
	public address!: string;
	public tel_fax!: string;
	public end_user!: string;
}
