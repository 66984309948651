import { Vue, setup } from "vue-class-component";
import { widget } from "@cems-eco/core/src/utils/widget";
import { Equipment, equipmentFactory } from "../services";
import $ from "jquery";
import environment from "@/config";
import storage from "@/storage";

export default class EquipmentPage extends Vue {

    equipmentBloc = setup(() => equipmentFactory())
    equipments: object[] = []
    options = {}
    events: object[] = []
    equipment = new Equipment
    clientOptions: any = {
        placeholder: '',
        allowClear: true,
        data: [],
    };
    siteOptions: any = {
        placeholder: '',
        allowClear: true,
        disabled: true,
        data: [],
    };
    equipmentOptions: any = {
        placeholder: '',
        allowClear: true,
        disabled: true,
    };
    isSearching = true;
    dt: any;

    created(): void {
        this.clientOptions = this.initOption(environment.API_URL + '/client/select2');
        this.siteOptions = { disabled: true, ...this.initOption(environment.API_URL + '/site/select2') };
        this.equipmentOptions = { disabled: true, ...this.initOption(environment.API_URL + '/equipment/select2') };

    }

    mounted() {
        this.builDataTable()
    }

    initOption(endpoint: string) {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        return {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url: endpoint,
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    async builDataTable() {
        const loader: any = await widget.loading();
        const scope: any = this;
        loader.showLoading();


        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        this.options = {
            serverSide: true,
            searching: !this.isSearching,
            ajax: {
                url: this.equipmentBloc.base_url,
                method: 'GET',
                dataType: 'json',
                dataFilter: (data) => {
                    const parsedData = JSON.parse(data);
                    return JSON.stringify(parsedData.data);
                },
                headers,
            },
            columnDefs: [
                {
                    "targets": -1,
                    "width": "1%"
                },
            ],
            columns: [
                { data: 'client_name' },
                { data: 'site_name' },
                { data: 'name' },
                {
                    title: "Action",
                    data: "id",
                    render: function (id: any, type: any, full: any, meta: any) {
                        return (
                            '<div class="form-group" style="margin: auto;">' +
                            '<div class="btn-group" role="group">' +
                            `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye" data-placement="top" title="View" ></i></button>` +
                            `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" data-placement="top" title="Edit"></i></button>` +
                            `<button type="button" class="btn btn-danger btn-sm delete"id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
                            `<button type="button" class="btn btn-warning btn-sm duplicate" id="duplicateAction"><i class="far fa-regular fa-copy" ></i></button>` +
                            "</div>" +
                            "</div>"
                        );
                    }
                }
            ],
            createdRow: function (row: any, data: any) {
                $(row).on("click", "#viewAction", scope.viewData.bind(this, data, scope));
                $(row).on("click", "#editAction", scope.editData.bind(this, data, scope));
                $(row).on("click", "#deleteAction", scope.deleteData.bind(this, data, scope));
                $(row).on("click", "#duplicateAction", scope.duplicateData.bind(this, data, scope));
            }
        };
        loader.close();
    }

    dtInstance(dt) {
        this.dt = dt;
    }

    clientSelectChanged(data: any[]) {
        this.equipment.client_id = data[0].id;
        const isDisabled = this.equipment.client_id == undefined || this.equipment.client_id == '';
        this.siteOptions.disabled = isDisabled;
        if (!this.equipmentOptions.disabled) {
            this.equipmentOptions.disabled = isDisabled;
            this.equipmentOptions.data = [];
        }
        if (isDisabled) this.siteOptions.data = []
        this.siteOptions.ajax.url = environment.API_URL + '/site/select2/client/' + this.equipment.client_id;
    }

    siteSelectChanged(data: any[]) {
        this.equipment.site_id = data[0].id;
        const isDisabled = this.equipment.site_id == undefined || this.equipment.site_id == '';
        this.equipmentOptions.disabled = isDisabled;
        if (isDisabled) this.equipmentOptions.data = []
        this.equipmentOptions.ajax.url = environment.API_URL + '/equipment/select2/site/' + this.equipment.site_id;

    }

    equipmentSelectChanged(data: any[]) {
        this.equipment.id = data[0].id;
    }

    openSearch() {
        this.isSearching = !this.isSearching;
        this.builDataTable();
    }

    async searching() {
        const searchUrl = await this.equipmentBloc.searchParams(this.equipment);
        this.dt.ajax.url(searchUrl);
        this.dt.ajax.reload();
    }

    async viewData(data: any) {
        this.$router.push(`/equipment/${data.id}`);
    }
    async duplicateData(data: any) {
        this.$router.push(`/equipment/create?duplicate=${data.id}`);
    }

    async editData(data: any) {
        this.$router.push(`/equipment/${data.id}/edit`);
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.')
                    return;
                }
                let message = await this.equipmentBloc.destroyEquipment(data.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.builDataTable();
            } catch (error) {
                widget.alertError(error);
            }
        })
    }

}

