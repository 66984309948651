import { api } from '@cems-eco/core/src/services/api';

export class UserApi {
    private BASE_URL: string;
    private BASE_PERMISSION_URL: string;
    private BASE_USER_DETAILS_URL: string;

    
    constructor() {
        this.BASE_URL = "/user";
        this.BASE_PERMISSION_URL = "/permission/module"
        this.BASE_USER_DETAILS_URL = this.BASE_URL + "/detail"
    }

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async create(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async edit(id: any, data: any): Promise<any> {
        return await api.put<any>(`${this.BASE_URL}/${id}`, data, true);
    }

    async destroy(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_URL}/${id}`, true);
    }

    async getPermissions(): Promise<any> {
        return await api.get<any>(this.BASE_PERMISSION_URL, true);
    }

    async getUserDetail(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_USER_DETAILS_URL}/${id}`, true);
    }

    async createUserDetail(data: any): Promise<any> {
        return await api.post<any>(this.BASE_USER_DETAILS_URL, data, true);
    }

    async updateUserDetail(id: any, data: any): Promise<any> {
        return await api.put<any>(`${this.BASE_USER_DETAILS_URL}/${id}`, data, true);
    }

    async deleteUserDetail(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_USER_DETAILS_URL}/${id}`, true);
    }
}

const userApi = new UserApi();
export { userApi };
