import { Vue, setup } from "vue-class-component";
import { widget } from "@cems-eco/core/src/utils/widget";
import { Site, siteFactory } from "../services";
import $ from "jquery";
import environment from "@/config";
import storage from "@/storage";

export default class SitePage extends Vue {

    siteBloc = setup(() => siteFactory())
    sites: object[] = []
    options = {}
    events: object[] = []
    site = new Site
    isSearching = true;
    dt: any;

    clientOptions: any = {
        placeholder: '-- Please select --',
        allowClear: true,
    };
    siteOptions: any = {
        placeholder: '-- Please select --',
        allowClear: true,
    };

    created(): void {
        this.clientOptions = this.initOption(environment.API_URL + '/client/select2');
        this.siteOptions = this.initOption(environment.API_URL + '/site/select2');
    }

    mounted() {
        this.builDataTable()
    }

    initOption(endpoint: string) {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        return {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url: endpoint,
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    async builDataTable() {
        const loader: any = await widget.loading();
        const scope: any = this;
        loader.showLoading();

        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }
        this.options = {
            mobileView: {
                header: {
                    actions: [
                        {
                            id: 'viewAction',
                            template: '<i class="far fa-eye text-success" title="View" ></i> View',
                        },
                        {
                            id: 'editAction',
                            template: '<i class="fas fa-edit text-primary" title="View" ></i> Update',
                        },
                        {
                            id: 'deleteAction',
                            template: '<i class="far fa-trash-alt text-danger" title="Delete" ></i> Delete',
                        },
                        {
                            id: 'duplicateAction',
                            template: '<i class="far fa-regular fa-copy text-warning" title="Duplicate" ></i> Duplicate',
                        },
                    ],
                    title: function (data: any) {
                        return data.name;
                    },
                }
            },
            serverSide: true,
            searching: !this.isSearching,
            ajax: {
                url: this.siteBloc.base_url,
                method: 'GET',
                dataType: 'json',
                dataFilter: (data) => {
                    const parsedData = JSON.parse(data);
                    return JSON.stringify(parsedData.data);
                },
                headers,
            },
            columnDefs: [
                {
                    "targets": -1,
                    "width": "1%"
                },
            ],
            columns: [
                {
                    data: 'client_name',
                    title: 'Client',
                    mobileView: true
                },
                {
                    data: 'name',
                    title: 'Name',
                    mobileView: true
                },
                {
                    title: "Action",
                    mobileView: false,
                    data: "id",
                    render: function (id: any, type: any, full: any, meta: any) {
                        return (
                            '<div class="form-group" style="margin: auto;">' +
                            '<div class="btn-group" role="group">' +
                            `<button type="button" class="btn btn-success btn-sm view" id="viewAction"><i class="far fa-eye" data-placement="top" title="View" ></i></button>` +
                            `<button type="button" class="btn btn-primary btn-sm edit" id="editAction"><i class="fas fa-edit" data-placement="top" title="Edit"></i></button>` +
                            `<button type="button" class="btn btn-danger btn-sm delete"id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
                            `<button type="button" class="btn btn-warning btn-sm duplicate" id="duplicateAction"><i class="far fa-regular fa-copy" ></i></button>` +
                            "</div>" +
                            "</div>"
                        );
                    }
                }
            ],
            createdRow: function (row: any, data: any) {
                $(row).on("click", "#viewAction", scope.viewData.bind(this, data, scope));
                $(row).on("click", "#editAction", scope.editData.bind(this, data, scope));
                $(row).on("click", "#deleteAction", scope.deleteData.bind(this, data, scope));
                $(row).on("click", "#duplicateAction", scope.duplicateData.bind(this, data, scope));
            }
        };
        loader.close();
    }

    dtInstance(dt) {
        this.dt = dt;
    }

    clientSelectChanged(data: any[]) {
        this.site.client_id = data[0].id;
    }

    siteSelectChanged(data: any[]) {
        this.site.id = data[0].id;
    }

    openSearch() {
        this.isSearching = !this.isSearching;
        this.builDataTable();
    }

    async searching() {
        const searchUrl = await this.siteBloc.searchParams(this.site);
        this.dt.ajax.url(searchUrl);
        this.dt.ajax.reload();
    }

    async viewData(data: any) {
        this.$router.push(`/site/${data.id}`);
    }

    async duplicateData(data: any) {
        this.$router.push(`/site/create?duplicate=${data.id}`);
    }

    async editData(data: any) {
        this.$router.push(`/site/${data.id}/edit`);
    }

    async deleteData(data: any) {
        widget.alertDelete().then(async (result) => {
            try {
                if (!result.isConfirmed) {
                    widget.alertSuccess('Deletion abort.', 'Your data is save.')
                    return;
                }
                let message = await this.siteBloc.destroySite(data.id);
                widget.alertSuccess('Good Job!', 'Your data has been deleted.');
                this.builDataTable();
            } catch (error) {
                widget.alertError(error);
            }
        })
    }

}

