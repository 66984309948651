import { Site } from "../../site/services";
import { Client } from "../../client/services";

export interface IEquipment {
    readonly id: any;
    name: string
    description: string
}

export class Equipment {
    public id!: any;
    public _method!: string;
    public client_id!: string;
    public site_id!: string;
    public name!: string;
    public manufacturer!: string;
    public model!: string;
    public date_manufactured!: string;
    public serial_number!: string;
    public additional!: string;

}